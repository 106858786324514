import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Stepper, Button } from '../../src';
import { Row } from './helpers';
import CustomStepper from './helpers/CustomStepper';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "stepper"
    }}>{`Stepper`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Stepper from 'mfcl/Stepper'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Steppers display progress through a sequence of logical and numbered steps. They may also be used for navigation. Steppers may display a transient feedback message after a step is saved.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Stepper} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<Stepper activeStep={1} steps={[false, false, false, false]} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Stepper,
      Button,
      Row,
      CustomStepper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Stepper activeStep={1} steps={[false, false, false, false]} mdxType="Stepper" />
    </Playground>
    <h2 {...{
      "id": "vertical"
    }}>{`Vertical`}</h2>
    <Playground __position={2} __code={'<Stepper vertical activeStep={2} steps={[false, false, false, false]} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Stepper,
      Button,
      Row,
      CustomStepper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Stepper vertical activeStep={2} steps={[false, false, false, false]} mdxType="Stepper" />
    </Playground>
    <h2 {...{
      "id": "labels"
    }}>{`Labels`}</h2>
    <Playground __position={3} __code={'<Stepper\n  activeStep={2}\n  color=\"#d63426\"\n  steps={[\n    { label: \'Step One\' },\n    { label: \'Step Two\' },\n    { label: \'Step Three\' },\n    { label: \'Step Four\' },\n  ]}\n/>\n<Stepper\n  activeStep={0}\n  color=\"#d63426\"\n  steps={[\n    { label: \'Step One\' },\n    { label: \'Step Two\' },\n    { label: \'Step Three\' },\n    { label: \'Step Four\' },\n  ]}\n  vertical\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Stepper,
      Button,
      Row,
      CustomStepper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Stepper activeStep={2} color="#d63426" steps={[{
        label: 'Step One'
      }, {
        label: 'Step Two'
      }, {
        label: 'Step Three'
      }, {
        label: 'Step Four'
      }]} mdxType="Stepper" />
  <Stepper activeStep={0} color="#d63426" steps={[{
        label: 'Step One'
      }, {
        label: 'Step Two'
      }, {
        label: 'Step Three'
      }, {
        label: 'Step Four'
      }]} vertical mdxType="Stepper" />
    </Playground>
    <h2 {...{
      "id": "custom-css-stepper"
    }}>{`Custom CSS Stepper`}</h2>
    <Playground __position={4} __code={'<CustomStepper />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Stepper,
      Button,
      Row,
      CustomStepper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CustomStepper mdxType="CustomStepper" />
    </Playground>
    <h2 {...{
      "id": "controlled-and-dynamic-stepper"
    }}>{`Controlled and Dynamic Stepper`}</h2>
    <Playground __position={5} __code={'<Component\n  initialState={{\n    activeStep: 0,\n    steps: [{ label: \'Label 1\' }, { label: \'Label 2\' }, { label: \'Label 3\' }],\n  }}\n>\n  {({ setState, state }) => (\n    <>\n      <Stepper\n        activeStep={state.activeStep}\n        selectIndex={i => setState({ activeStep: i })}\n        steps={state.steps}\n      />\n      <br></br>\n      <br></br>\n      <br></br>\n      <br></br>\n      <Row>\n        <Button\n          size=\"md\"\n          disabled={state.activeStep === 0}\n          onClick={() => setState({ activeStep: state.activeStep - 1 })}\n        >\n          Previous Page\n        </Button>\n        <Button\n          size=\"md\"\n          disabled={state.activeStep === state.steps.length - 1}\n          onClick={() => setState({ activeStep: state.activeStep + 1 })}\n        >\n          Next Page\n        </Button>\n        <Button\n          size=\"md\"\n          onClick={() =>\n            setState({\n              steps: [\n                ...state.steps,\n                { label: `Label ${state.steps.length + 1}` },\n              ],\n            })\n          }\n        >\n          Add Step\n        </Button>\n        <Button\n          size=\"md\"\n          onClick={() =>\n            setState({ steps: state.steps.slice(0, -1), activeStep: 0 })\n          }\n        >\n          Remove Step\n        </Button>\n      </Row>\n    </>\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Stepper,
      Button,
      Row,
      CustomStepper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        activeStep: 0,
        steps: [{
          label: 'Label 1'
        }, {
          label: 'Label 2'
        }, {
          label: 'Label 3'
        }]
      }} mdxType="Component">
    {({
          setState,
          state
        }) => <>
        <Stepper activeStep={state.activeStep} selectIndex={i => setState({
            activeStep: i
          })} steps={state.steps} mdxType="Stepper" />
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Row mdxType="Row">
          <Button size="md" disabled={state.activeStep === 0} onClick={() => setState({
              activeStep: state.activeStep - 1
            })} mdxType="Button">
            Previous Page
          </Button>
          <Button size="md" disabled={state.activeStep === state.steps.length - 1} onClick={() => setState({
              activeStep: state.activeStep + 1
            })} mdxType="Button">
            Next Page
          </Button>
          <Button size="md" onClick={() => setState({
              steps: [...state.steps, {
                label: `Label ${state.steps.length + 1}`
              }]
            })} mdxType="Button">
            Add Step
          </Button>
          <Button size="md" onClick={() => setState({
              steps: state.steps.slice(0, -1),
              activeStep: 0
            })} mdxType="Button">
            Remove Step
          </Button>
        </Row>
      </>}
  </Component>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      